//import React, { useState } from 'react';
//import { Link } from "react-router-dom";



import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCheck} from "@fortawesome/free-solid-svg-icons";



function ThankYou() {
  
  window.scroll(0,0);
  return (
    <div className='thankYou page'>
      <div className="container text-center">
        <div className="checkIcon">
          <FontAwesomeIcon icon={faCheck} />
        </div>
        <h1>Thank you so much for signing up!</h1>
        <p>We will notify you as soon as TimeGO is ready for you to use.</p>
      </div>
    </div>
  );
}

export default ThankYou;
