import React from 'react';
//import { Link } from "react-router-dom";

import priceBanner from './../../assets/images/priceBanner.png';
import listIcon from './../../assets/images/listIcon.png';


// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import the icons you need
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";



function Pricing() {
  
  window.scroll(0,0);

  return (
    <div className='pricing page'>
        <div className='pricPageBanner bannerSection'>
          <div className='container'>
            <h1>Fair & <span>Simple</span> Pricing</h1>
            <p>On the premium tier, you are charged at the end of the month for all active users only. You can set the status of any user to active or inactive at any time. All features are included. Pay month-to-month only, no long-term contracts. Cancel at any time, you will pay for the whole month when you cancel.</p>
            <p>When you sign up, you get 14 days to try out all the premium features. If you don’t upgrade to the premium tier, premium features get disabled but you can continue using features included in the free tier.</p>
          </div>
        </div>
        <div className='container'>
            <div className='pricePagebannerContent'><img src={priceBanner} alt="" /></div>

            <div className='mobileTableDetails'>
              <div className='tableDetailsHolder flextab'>
                <small>Free Tier</small> <span>$0</span>
              </div>
              <div className='tableDetailsHolder'>
                <small>Premium Tier</small>
                <div className='tableListitems'>
                  <div className='tablelist'>
                    <div className='listIcon'><img src={listIcon} alt="" /></div>
                    <div className='listContent'><span>$6</span> per active user per month</div>
                  </div>
                  <div className='tablelist'>
                    <div className='listIcon'><img src={listIcon} alt="" /></div>
                    <div className='listContent'><span>$25</span> base rate per month</div>
                  </div>
                  <div className='tablelist'>
                    <div className='listIcon'><img src={listIcon} alt="" /></div>
                    <div className='listContent'><span>$5</span> per 100 MB of uploaded docs per month</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='priceTableHolder'>
              <div className="tableHolder">
                <table className="table align-middle">
                  <thead>
                    <tr>
                      <th>Feature</th>
                      <th valign="top">
                        <small>Free Tier</small>
                        <span>$0</span>
                      </th>
                      <th valign="top">
                        <small>Premium Tier</small>
                        <div className='tableListitems'>
                          <div className='tablelist'>
                            <div className='listIcon'><img src={listIcon} alt="" /></div>
                            <div className='listContent'><span>$6</span> per active user per month</div>
                          </div>
                          <div className='tablelist'>
                            <div className='listIcon'><img src={listIcon} alt="" /></div>
                            <div className='listContent'><span>$25</span> base rate per month</div>
                          </div>
                          <div className='tablelist'>
                            <div className='listIcon'><img src={listIcon} alt="" /></div>
                            <div className='listContent'><span>$5</span> per 100 MB of uploaded docs per month</div>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-label="Feature">Employee Scheduling</td>
                      <td data-label="Free Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Unlimited Companies or Clients/Locations/Contacts</td>
                      <td data-label="Free Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Unlimited Employees/Jobs/Positions</td>
                      <td data-label="Free Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Employee Performance Log</td>
                      <td data-label="Free Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Mobile Employees can View Upcoming Schedule</td>
                      <td data-label="Free Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Mobile Employee Chat and Job Chat</td>
                      <td data-label="Free Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Employee Time-off Requests and Manager Approval</td>
                      <td data-label="Free Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Calendar View of Time Off Requests and other important dates</td>
                      <td data-label="Free Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Mobile Clockin/Clockout Time Tracking</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Mobile GPS Tracking and Geofence</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Job Log to Track Job Activities and Job Documents</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Submit and Approve Job Expenses</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Employee Documents</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Unlimited Custom Forms using Form Builder with unlimited Form Submissions</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Checkpoint Patrol with Virtual GPS checkpoints, QR codes or NFC tags</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Shift To-Do Lists and Employee To-Do Lists</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Map View of Job Locations, Employees, Who’s Working</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Optionally Provide Clients with Direct Access to Form Results, Checkpoint Scans and GPS Tracking</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Reports</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Equipment List and Log</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                    <tr>
                      <td data-label="Feature">Invoicing</td>
                      <td data-label="Free Tier"></td>
                      <td data-label="Premium Tier"><FontAwesomeIcon icon={faSquareCheck} /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
        <div className='pricingBottom'>
          <div className='container text-center'>
            <h2>Start Saving Your Time Now</h2>
            <h3>Get Started Free with <span>Time<span>GO</span></span></h3>
          </div>
        </div>
    </div>
  );
}

export default Pricing;
