//import React, { useState } from 'react';
//import { Link } from "react-router-dom";


// Import the FontAwesomeIcon component
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import the icons you need
//import { faStar } from "@fortawesome/free-solid-svg-icons";



import signupImage from './../../assets/images/signupImage.png';
import Logo from './../../assets/images/logo.png';

function SignUp () {
  
  window.scroll(0,0);
  return (
    <div className='signup page'>
        <div className="container">
          <div className='topMobileLogo'>
            <img src={Logo} alt="" />
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-7">
              <div className="signupLeft">
                <div className="signupLeftTop">
                  <h1>Sign up for <span>early</span> <br />access to TimeGO</h1>
                  <p>At TimeGO, our mission is to make your business more profitable, your operations easier, faster and more automated, and give you better insight into your financial performance.</p>
                </div>
                <div className='signupBottom'>
                  <img src={signupImage} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div class="signUpRight">
                <div className='signUpLogo'>
                  <img src={Logo} alt="" />
                </div>
                <h2>Kindly tell us a bit about yourself <br /> and your company</h2>
                <div className='formHolder'>
                  <form action='/thank-you'>
                    <input type="text" placeholder='Name' />
                    <input type="email" placeholder='Work Email' />
                    <input type="text" placeholder='Company Name ' />
                    <select>
                      <option>Number of Employees [1 to 10] </option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                    <label>Are you ready to save time and effort and boost profit? YES!</label>
                    <input type="submit" value="Sign up for an early invite" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default SignUp;
