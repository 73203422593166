import {  Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/css/style.css';



import { Header, Footer } from "./components";
import Home from "./components/view/Home";
import Pricing from "./components/view/Pricing";
import Blog from "./components/view/Blog";
import Faq from "./components/view/Faq";
import SignUp from "./components/view/SignUp";
import ThankYou from "./components/view/ThankYou";
import NoPage from "./components/view/NoPage";




function App() {

  let location = useLocation();

  return (
   
    <div>
      {location.pathname !== '/signup' && <Header/>}
      <TransitionGroup>
          <CSSTransition key={location.key} timeout={450} classNames="fade">
            <Routes>
              <Route>
                <Route index element={<Home />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="blog" element={<Blog />} />
                <Route path="faqs" element={<Faq />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="thank-you" element={<ThankYou />} />
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </CSSTransition>
      </TransitionGroup>
      {location.pathname !== '/signup' && <Footer/>}
    </div>
    
  );
}

export default App;
