//import React, { useState } from 'react';
//import { Link } from "react-router-dom";


// Import the FontAwesomeIcon component
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import the icons you need
//import { faStar } from "@fortawesome/free-solid-svg-icons";

import faqbanner from './../../assets/images/faqbanner.png';




function Faq() {
  
  window.scroll(0,0);
  return (
    <div className='faq page'>
        <div className='faqBanner bannerSection'>
          <div className='container'>
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-8">
                <h1>Frequently <span>Asked</span> Questions</h1>
              </div>
              <div className="col-lg-4">
                <div className="faqBannerImage">
                  <img src={faqbanner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='faqContent'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-10'>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        What is Novagems?
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irurereprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident sunt in culpa quiofficia.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Do you offer discounts?
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irurereprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident sunt in culpa quiofficia.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Is my data secure?
                      </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irurereprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident sunt in culpa quiofficia.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}

export default Faq;
